import {
  ACCEPT_AGREEMENT,
  ASSESSMENTS_LIST_CLEAR_FILTER,
  ASSESSMENTS_LIST_FILTER_ON,
  ASSESSMENTS_LIST_SEARCHED_ON,
  CANCEL_EDIT_EVENT_DATE,
  CLICK_ACCEPT_CONTEXT_SCREEN,
  CLICK_ADD_CANDIDATE_PATIENT,
  CLICK_ADD_NEW_DISEASE_EXPAND,
  CLICK_ADD_NEW_DISEASE_KEBAB,
  CLICK_ADD_NEW_DISEASE_KEBAB_ADMINISTRATIVE,
  CLICK_ADD_NEW_DISEASE_NEW_CONDITION,
  CLICK_ADD_NEW_DISEASE_NEW_CONDITION_ADMINISTRATIVE,
  CLICK_ADD_PATIENT,
  CLICK_AGREEMENT_SCROLL_TO_BOTTOM,
  CLICK_ANSWER_NEXT_PROM,
  CLICK_APP_DOWNLOAD_BANNER,
  CLICK_AVAILABLE_QUESTIONNAIRES,
  CLICK_CANCEL_ADD_PATIENT,
  CLICK_CHANGE_LANGUAGE,
  CLICK_CONFIRM_OPT_OUT,
  CLICK_CONTINUE_QUESTIONNAIRES,
  CLICK_COPY_TO_CLIPBOARD,
  CLICK_DISEASE_TAB_EXPAND,
  CLICK_EXPAND_EVENT_TIMELINE,
  CLICK_FEATURED_PATIENT_TOGGLE,
  CLICK_LEARN_MORE_QUESTIONNAIRE_RESULTS,
  CLICK_LOGIN_FORM_ID_SUBMIT,
  CLICK_LOGIN_FORM_PASSWORD_SUBMIT,
  CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD,
  CLICK_MAIN_NAV,
  CLICK_NEW_CONDITION_OVERVIEW,
  CLICK_NEXT_QUESTIONAIRE_TIMELINE_EXPAND,
  CLICK_OPEN_ASSESSMENTS_NAV,
  CLICK_OPEN_EDIT_EVENT_DATE,
  CLICK_OPEN_FILTER_ASSESSMENTS_LIST,
  CLICK_OPEN_PATIENTS_SEARCH_FILTERS,
  CLICK_OPEN_PATIENT_CONDITION_KEBAB,
  CLICK_OPEN_PATIENT_CONDITION_ROW,
  CLICK_OPEN_PATIENT_DETAIL_EXPAND,
  CLICK_OPEN_PATIENT_EXPAND,
  CLICK_OPEN_PATIENT_KEBAB,
  CLICK_OPT_OUT,
  CLICK_OPT_OUT_BTN_IDENTITY_CHECKER,
  CLICK_PATIENT_DETAIL_FEATURED_PATIENT,
  CLICK_PATIENT_MEASUREMENTS_TAB,
  CLICK_PATIENT_MEASUREMENTS_WIDGET,
  CLICK_PROCCED_BTN_IDENTITY_CHECKER,
  CLICK_PROCCED_BTN_IDENTITY_CHECKER_REVALIDATION,
  CLICK_PROM_FINISH,
  CLICK_QUESTIONNAIRES_RESULTS,
  CLICK_QUICK_FILTER_ASSESSMENTS_LIST,
  CLICK_REFER_PATIENT_KEBAB,
  CLICK_REFER_PATIENT_SELECT_DOCTOR,
  CLICK_REFER_PATIENT_SUBMIT,
  CLICK_RESTART_QUESTIONNAIRE_BUTTON,
  CLICK_RESUME_QUESTIONNAIRE_BUTTON,
  CLICK_SEND_QUESTIONNAIRE_TO_PATIENT,
  CLICK_START_CLINICIAN_QUESTIONNAIRE,
  CLICK_START_PATIENT_ASSESSMENT_KEBAB,
  CLICK_START_PATIENT_QUESTIONNAIRE,
  CLICK_VALIDATE_PATIENT_CONTACT,
  CONTINUE_QUESTIONNAIRE_ON_ONGOING_QUESTIONNAIRES,
  CRO_SAVED,
  CRO_TAB_CHANGED,
  DISPLAY_VALIDATE_ACCOUNT_ON_QUESTIONNAIRE,
  EDIT_EVENT_DATE,
  ENTER_CONTEXT_SCREEN,
  ENTER_IDENTITY_CHECKER_CANCEL_COMMS_PAGE,
  ENTER_IDENTITY_CHECKER_PAGE,
  ENTER_IDENTITY_CHECKER_REVALIDATION_PAGE,
  ENTER_MY_QUEST,
  ENTER_PROM_RESUME,
  EVENT_CREATED,
  IDENTIFY_USER_AS_PATIENT,
  MYQUEST_LOGOUT,
  OPEN_PATIENT_CONDITION_MENU_OVERVIEW,
  OPEN_PROFILE_MYQUEST,
  OPEN_SETTINGS_MYQUEST,
  PROFESSIONAL_CHANGES_TAB_TODOLIST,
  PROFESSIONAL_CLICKS_CLOSE_MANAGE_COLUMNS_TODOLIST,
  PROFESSIONAL_CLICKS_KEBAB_HEADER_TODOLIST,
  PROFESSIONAL_CLICKS_KEBAB_MANAGE_TODOLIST,
  PROFESSIONAL_CLICKS_KEBAB_TABLELINE_TODOLIST,
  PROFESSIONAL_CLICKS_MANAGE_COLUMNS_TODOLIST,
  PROFESSIONAL_CLICKS_SAVE_MANAGE_COLUMNS_TODOLIST,
  PROFESSIONAL_CLICKS_TRANSCRIBEPROM_TODOLIST,
  PROFESSIONAL_HOVERS_COMMSHISTORY_TODOLIST,
  PROFESSIONAL_HOVERS_PATIENTINFO_TODOLIST,
  PROFESSIONAL_OPENS_TODOLIST,
  PROFESSIONAL_SEARCHES_TODOLIST,
  PROFESSIONAL_UPDATE_FILTER_TODOLIST,
  PROFESSIONAL_UPDATE_SORT_TODOLIST,
  SELECT_PATIENT_CONDITION,
  SET_PASSWORD,
  START_NEW_CROM_NEW_CONDITION,
  START_NEW_PROM_NEW_CONDITION,
  START_PROM_SPECIFIC_TIMING_ASSESSMENTS,
  START_PROM_SPECIFIC_TIMING_OVERVIEW,
  START_QUESTIONNAIRE_ON_AVAILABLE_QUESTIONNAIRES,
  TRACK_CLICK_GOBACK_REJECT_TC,
  TRACK_CLICK_REJECT_TC,
  TRACK_OPEN_TC_PAGE,
  TRACK_OPEN_ASSESSMENTS_INFO,
  TRACK_OPEN_INSTITUTION_CONSENT_PAGE,
  TRACK_OPEN_SCORE_APPRECIATION,
  TRACK_PATIENTS_LIST_CHANGE_PAGE,
  TRACK_PROFESSIONAL_GLOBAL_SEARCH,
  TRACK_PROFESSIONAL_PATIENT_SEARCH,
  TRACK_OPEN_CONNECT_DEVICE_PAGE,
  TRACK_CLICK_CONTINUE_CONNECT_DEVICE_PAGE,
  TRACK_CLICK_BACK_CONFIRM_DEVICE_PAGE,
  TRACK_CLICK_CONNECT_CONFIRM_DEVICE_PAGE,
  TRACK_SUCCESS_CONNECT_DEVICE,
  TRACK_OPEN_DEVICE_WELCOME_PAGE,
  TRACK_CLICK_START_DEVICE_WELCOME_PAGE,
  TRACK_OPEN_DEVICE_NO_QUESTIONNAIRES,
  TRACK_OPEN_DEVICE_LANGUAGE_MENU,
  TRACK_CLICK_DEVICE_LANGUAGE_MENU
} from '../constants/actionTypes';

export const onPasswordSuccessSubmit = () => ({
  type: SET_PASSWORD
});

export const onAcceptAgreement = () => ({
  type: ACCEPT_AGREEMENT
});

export const onClickAppBanner = () => ({
  type: CLICK_APP_DOWNLOAD_BANNER
});

export const onClickLoginIdSubmit = () => ({
  type: CLICK_LOGIN_FORM_ID_SUBMIT
});

export const onClickLoginPasswordSubmit = () => ({
  type: CLICK_LOGIN_FORM_PASSWORD_SUBMIT
});

export const onClickLoginFormShowHidePassword = () => ({
  type: CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD
});

export const onClickAddPatient = () => ({
  type: CLICK_ADD_PATIENT
});

export const onClickAddCandidatePatient = () => ({
  type: CLICK_ADD_CANDIDATE_PATIENT
});

export const onClickValidatePatientContact = () => ({
  type: CLICK_VALIDATE_PATIENT_CONTACT
});

export const onClickStartPatientQuestionnaire = () => ({
  type: CLICK_START_PATIENT_QUESTIONNAIRE
});

export const onClickStartClinicianQuestionnaire = () => ({
  type: CLICK_START_CLINICIAN_QUESTIONNAIRE
});

export const onClickCancelAddPatient = () => ({
  type: CLICK_CANCEL_ADD_PATIENT
});

// Track for click on click to expand
export const onClickPatientExpand = () => ({
  type: CLICK_OPEN_PATIENT_EXPAND
});

// Track for click on patients' disease detail by click to expand
export const onClickPatientExpandDetail = () => ({
  type: CLICK_OPEN_PATIENT_DETAIL_EXPAND
});

// Track for click on patient kebab
export const onClickPatientKebab = () => ({
  type: CLICK_OPEN_PATIENT_KEBAB
});

// Track for click on patient condition by kebab
export const onClickPatientConditionKebab = () => ({
  type: CLICK_OPEN_PATIENT_CONDITION_KEBAB
});

// Track for click on start patient assessment by kebab
export const onClickStartPatientAssessmentKebab = () => ({
  type: CLICK_START_PATIENT_ASSESSMENT_KEBAB
});

export const onClickRequestPatientAssessmentKebab = () => ({
  type: CLICK_START_PATIENT_ASSESSMENT_KEBAB
});

// Track click when sending questionnaire to patient
export const onClickSendQuestionnaireToPatient = ({
  source,
  user,
  patientUid,
  diseaseSlug,
  diseaseName,
  institutionSlug,
  institutionName,
  tab,
  daysToClose,
  timing,
  daysFromLastComm,
  statusComm,
  questionnaireUid
}) => ({
  type: CLICK_SEND_QUESTIONNAIRE_TO_PATIENT,
  payload: {
    source,
    user,
    patientUid,
    diseaseSlug,
    diseaseName,
    institutionSlug,
    institutionName,
    tab,
    daysToClose,
    timing,
    daysFromLastComm,
    statusComm,
    questionnaireUid
  }
});

// Track for click on refer patient by kebab
export const onClickReferPatientKebab = () => ({
  type: CLICK_REFER_PATIENT_KEBAB
});

// Track for click on submit refer patient
export const onClickReferPatientSubmit = () => ({
  type: CLICK_REFER_PATIENT_SUBMIT
});

// Track for click select doctor on refer patient
export const onClickReferPatientSelectDoctor = () => ({
  type: CLICK_REFER_PATIENT_SELECT_DOCTOR
});

// Track for click on patient row
export const onClickPatientRow = () => ({
  type: CLICK_OPEN_PATIENT_CONDITION_ROW
});

// Track for click on patients search filters
export const onClickPatientsSearchFilters = () => ({
  type: CLICK_OPEN_PATIENTS_SEARCH_FILTERS
});

// Track for professional global search
export const onProfessionalGlobalSearch = () => ({
  type: TRACK_PROFESSIONAL_GLOBAL_SEARCH
});

// Track for professional patients list search
export const onProfessionalPatientSearch = () => ({
  type: TRACK_PROFESSIONAL_PATIENT_SEARCH
});

// Track for professional page change in patients list
export const onClickNewPage = () => ({
  type: TRACK_PATIENTS_LIST_CHANGE_PAGE
});

// Track entering on prom resume
export const onEnterPromResume = () => ({
  type: ENTER_PROM_RESUME
});

// Track click on prom finish
export const onClickPromFinish = () => ({
  type: CLICK_PROM_FINISH
});

// Track click on assessments item on navbar
export const onClickAssessmentsNav = navUid => ({
  type: CLICK_OPEN_ASSESSMENTS_NAV,
  payload: {
    navUid
  }
});

// Track click on event timeline expand icon
export const onClickExpandEventsTimeline = payload => ({
  type: CLICK_EXPAND_EVENT_TIMELINE,
  payload
});

// Track click on start assessment from specific timing
export const onClickStartSpecificTiming = (payload, fromAssessments = false) => ({
  type: !fromAssessments ? START_PROM_SPECIFIC_TIMING_OVERVIEW : START_PROM_SPECIFIC_TIMING_ASSESSMENTS,
  payload
});

// Track click on open edit event date form
export const onClickOpenEditEventDate = () => ({
  type: CLICK_OPEN_EDIT_EVENT_DATE
});

// Track edit event date
export const eventDateEditted = payload => ({
  type: EDIT_EVENT_DATE,
  payload
});

// Track cancel edit event date
export const editEventDateCancelled = () => ({
  type: CANCEL_EDIT_EVENT_DATE
});

// Track create event
export const eventCreated = payload => ({
  type: EVENT_CREATED,
  payload
});

// Track cro tab change
export const croTabChanged = (value, label) => ({
  type: CRO_TAB_CHANGED,
  payload: {
    label
  }
});

// Track save cro event
export const croSaved = payload => ({
  type: CRO_SAVED,
  payload
});

// Track click copy to clipboard
export const clickCopyToClipboard = () => ({
  type: CLICK_COPY_TO_CLIPBOARD
});

// Track open thank you card on prom resume
export const scoreAppreciationOpened = () => ({
  type: TRACK_OPEN_SCORE_APPRECIATION
});

// Track open assessments info card on prom resume
export const assessmentsInfoOpened = () => ({
  type: TRACK_OPEN_ASSESSMENTS_INFO
});

// Track click on answer next prom
export const clickAnswerNextProm = () => ({
  type: CLICK_ANSWER_NEXT_PROM
});

// Track click on next questionaire expand
export const clickNextQuestionaireTimelineExpand = payload => ({
  type: CLICK_NEXT_QUESTIONAIRE_TIMELINE_EXPAND,
  payload
});

// Track Agreement Page load
export const termsAndConditionsPageOpened = payload => ({
  type: TRACK_OPEN_TC_PAGE,
  payload
});

// Track Agreement Page load
export const clickRejectTermsAndConditions = payload => ({
  type: TRACK_CLICK_REJECT_TC,
  payload
});

// Track Agreement Page load
export const clickGobackRejectTermsAndConditions = payload => ({
  type: TRACK_CLICK_GOBACK_REJECT_TC,
  payload
});

// Track Institution Consent Page load
export const institutionConsentPageOpened = payload => ({
  type: TRACK_OPEN_INSTITUTION_CONSENT_PAGE,
  payload
});

// Track click on Agreement Scroll to Bottom
export const clickAgreementScrollToBottom = payload => ({
  type: CLICK_AGREEMENT_SCROLL_TO_BOTTOM,
  payload
});

// Track start new prom at new condition page
export const newConditionPromStarted = () => ({
  type: START_NEW_PROM_NEW_CONDITION
});

// Track start of new crom at new condition page
export const newConditionCromStarted = () => ({
  type: START_NEW_CROM_NEW_CONDITION
});

// Track click on add new patient disease at patients list kebab
// by administrative
export const newPatientDiseaseKebabAdministrativeClicked = () => ({
  type: CLICK_ADD_NEW_DISEASE_KEBAB_ADMINISTRATIVE
});

// Track click on add new patient disease at new condition page
// by administrative
export const newPatientDiseaseNewConditionPageAdministrativeClicked = payload => ({
  type: CLICK_ADD_NEW_DISEASE_NEW_CONDITION_ADMINISTRATIVE,
  payload
});

// Track click on add new patient disease at new condition page
// by doctor
export const newPatientDiseaseNewConditionPageClicked = payload => ({
  type: CLICK_ADD_NEW_DISEASE_NEW_CONDITION,
  payload
});

export const newPatientDiseaseKebabClicked = () => ({
  type: CLICK_ADD_NEW_DISEASE_KEBAB
});

// Track click on add new patient disease at patients list expand
export const newPatientDiseaseExpandClicked = () => ({
  type: CLICK_ADD_NEW_DISEASE_EXPAND
});

// Track click on disease tab at expand
export const diseaseTabExpandClicked = diseaseName => ({
  type: CLICK_DISEASE_TAB_EXPAND,
  payload: {
    disease: diseaseName
  }
});

// Track openning of patient condition menu in overview
export const patientConditionMenuOpened = () => ({
  type: OPEN_PATIENT_CONDITION_MENU_OVERVIEW
});

// Track select patient condition on patient condition menu
export const patientConditionSelected = diseaseName => ({
  type: SELECT_PATIENT_CONDITION,
  payload: {
    disease: diseaseName
  }
});

// Track click on add new condition at patient conditions menu
export const addNewConditionOverviewClicked = () => ({
  type: CLICK_NEW_CONDITION_OVERVIEW
});

// Track click on patient measurements tab
export const onClickPatientMeasurementsTab = name => ({
  type: CLICK_PATIENT_MEASUREMENTS_TAB,
  payload: {
    name
  }
});

// Track click on patient measurements widget
export const onClickPatientMeasurementsWidget = name => ({
  type: CLICK_PATIENT_MEASUREMENTS_WIDGET,
  payload: {
    name
  }
});

// Track click on featured patient toggle
export const onClickFeaturedPatientToggle = type => ({
  type: CLICK_FEATURED_PATIENT_TOGGLE,
  payload: {
    type
  }
});

// Track click on patient detail of featured patient
export const onClickPatientDetailFeaturedPatient = () => ({
  type: CLICK_PATIENT_DETAIL_FEATURED_PATIENT
});

// Track click on accept Context Screen
export const onClickAcceptContextScreen = payload => ({
  type: CLICK_ACCEPT_CONTEXT_SCREEN,
  payload
});

// Track enter on Context Screen
export const onEnterContextScreenPage = payload => ({
  type: ENTER_CONTEXT_SCREEN,
  payload
});

// Track click on main nav, should tell which menu was clicked
export const onClickMainNav = payload => ({
  type: CLICK_MAIN_NAV,
  payload
});

// Track click on quick filters on assessment list
export const onClickQuickFiltersAssessmentList = payload => ({
  type: CLICK_QUICK_FILTER_ASSESSMENTS_LIST,
  payload
});

// Track click when user opens filters on assessment list
export const onOpenFiltersAssessmentList = payload => ({
  type: CLICK_OPEN_FILTER_ASSESSMENTS_LIST,
  payload
});

// Track what user filtered on assessement list filters
export const onClickFiltersAssessmentList = payload => ({
  type: ASSESSMENTS_LIST_FILTER_ON,
  payload
});

// Track click on Clear filters button on assessment list
export const clearFiltersAssessmentList = payload => ({
  type: ASSESSMENTS_LIST_CLEAR_FILTER,
  payload
});

// Track if user searched on assessment list
export const searchOnAssessmentList = payload => ({
  type: ASSESSMENTS_LIST_SEARCHED_ON,
  payload
});

// Identify User as Patient
export const identifyUserAsPatient = payload => ({
  type: IDENTIFY_USER_AS_PATIENT,
  payload
});

// Track click to unsubscribe
export const onClickOptOut = payload => ({
  type: CLICK_OPT_OUT,
  payload
});

// Track click to confirm unsubscribe
export const onClickConfirmOptOut = payload => ({
  type: CLICK_CONFIRM_OPT_OUT,
  payload
});

// Track enter identity checker page
export const onEnterIdentityChecker = payload => ({
  type: ENTER_IDENTITY_CHECKER_PAGE,
  payload
});

// Track click proceed on identity checker page
export const onClickProcceedIdentityChecker = payload => ({
  type: CLICK_PROCCED_BTN_IDENTITY_CHECKER,
  payload
});

// Track enter identity checker revalidation page
export const onEnterIdentityCheckerRevalidation = payload => ({
  type: ENTER_IDENTITY_CHECKER_REVALIDATION_PAGE,
  payload
});

// Track click proceed on identity checker page
export const onClickProcceedIdentityCheckerRevalidation = payload => ({
  type: CLICK_PROCCED_BTN_IDENTITY_CHECKER_REVALIDATION,
  payload
});

// Track click opt out button on identity checker
export const onClickOptOutIdentityChecker = payload => ({
  type: CLICK_OPT_OUT_BTN_IDENTITY_CHECKER,
  payload
});

// Track enter identity checker cancelled comms page
export const onEnterIdentityCheckerCancelComms = payload => ({
  type: ENTER_IDENTITY_CHECKER_CANCEL_COMMS_PAGE,
  payload
});

// Track click start over button in questionnaire when it has previous answers
export const onClickStartOverQuestionnaire = payload => ({
  type: CLICK_RESTART_QUESTIONNAIRE_BUTTON,
  payload
});

// Track click continue button in questionnaire when it has previous answers
export const onClickResumeQuestionnaire = payload => ({
  type: CLICK_RESUME_QUESTIONNAIRE_BUTTON,
  payload
});

// Track when Validation Node is shown on Questionnaire
export const onDisplayValidateAccountOnQuestionnaire = payload => ({
  type: DISPLAY_VALIDATE_ACCOUNT_ON_QUESTIONNAIRE,
  payload
});

// Track click change language and to what was changed
export const onClickChangeLanguage = payload => ({
  type: CLICK_CHANGE_LANGUAGE,
  payload
});

export const onClickLearnMoreQuestionnaireResults = payload => ({
  type: CLICK_LEARN_MORE_QUESTIONNAIRE_RESULTS,
  payload
});

// Track Connect device page open
export const onConnectDevicePageOpened = payload => ({
  type: TRACK_OPEN_CONNECT_DEVICE_PAGE,
  payload
});

// Track connect device continue button click
export const onClickContinueConnectDevice = payload => ({
  type: TRACK_CLICK_CONTINUE_CONNECT_DEVICE_PAGE,
  payload
});

// Track confirm device back button click
export const onClickBackConfirmDevice = payload => ({
  type: TRACK_CLICK_BACK_CONFIRM_DEVICE_PAGE,
  payload
});

// Track confirm device connect button click
export const onClickConnectConfirmDevice = payload => ({
  type: TRACK_CLICK_CONNECT_CONFIRM_DEVICE_PAGE,
  payload
});

// Track Connect device successfully
export const onSuccessConnectDevice = payload => ({
  type: TRACK_SUCCESS_CONNECT_DEVICE,
  payload
});

// Track device welcome page open
export const onDeviceWelcomePageOpened = payload => ({
  type: TRACK_OPEN_DEVICE_WELCOME_PAGE,
  payload
});

// Track device welcome start button click
export const onClickStartDeviceWelcome = payload => ({
  type: TRACK_CLICK_START_DEVICE_WELCOME_PAGE,
  payload
});

// Track device no questionnaires page open
export const onDeviceNoQuestionnairesPageOpened = payload => ({
  type: TRACK_OPEN_DEVICE_NO_QUESTIONNAIRES,
  payload
});

// Track kiosk language open
export const onKioskLanguageOpened = payload => ({
  type: TRACK_OPEN_DEVICE_LANGUAGE_MENU,
  payload
});

// Track kiosk language click
export const onKioskLanguageClick = payload => ({
  type: TRACK_CLICK_DEVICE_LANGUAGE_MENU,
  payload
});

// -------- MyQuest ----------

// Track patient enter my quest
export const onEnterMyQuest = () => ({
  type: ENTER_MY_QUEST
});

// Track patient clicks available questionnaires
export const onClickAvailableQuestionnaires = () => ({
  type: CLICK_AVAILABLE_QUESTIONNAIRES
});

// Track patient clicks continue questionnaires
export const onClickContinueQuestionnaires = () => ({
  type: CLICK_CONTINUE_QUESTIONNAIRES
});

// Track patient clicks questionnaires results
export const onClickQuestionnairesResults = () => ({
  type: CLICK_QUESTIONNAIRES_RESULTS
});

// Track patient starts questionnaire on available questionnnaires
export const onClickStartQuestionnaireOnAvailablePage = payload => ({
  type: START_QUESTIONNAIRE_ON_AVAILABLE_QUESTIONNAIRES,
  payload
});
// Track patient continue questionnaire on ongoing questionnnaires
export const onClickContinueQuestionnaireOnOngoingPage = payload => ({
  type: CONTINUE_QUESTIONNAIRE_ON_ONGOING_QUESTIONNAIRES,
  payload
});

// Track patient open profile menu on myQuest
export const onClickOpenProfileMyQuest = () => ({
  type: OPEN_PROFILE_MYQUEST
});

// Track patient  open profile menu on myQuest
export const onClickOpenSettingsMyQuest = () => ({
  type: OPEN_SETTINGS_MYQUEST
});

// Track patient click logout myquest
export const onClickLogoutMyQuest = () => ({
  type: MYQUEST_LOGOUT
});

// -------- END MyQuest ----------

// -------- TodoList V2 ----------

// Track professional change tab todo list
export const onChangeTabTodoList = payload => ({
  type: PROFESSIONAL_CHANGES_TAB_TODOLIST,
  payload
});

// Track professional search on todo list
export const onSearchTodoList = payload => ({
  type: PROFESSIONAL_SEARCHES_TODOLIST,
  payload
});

// Track professional click kebab to manage columns on todo list
export const onClickManageKebabTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_KEBAB_MANAGE_TODOLIST,
  payload
});

// Track professional click kebab on header of table on todo list
export const onClickKebabHeaderTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_KEBAB_HEADER_TODOLIST,
  payload
});

// Track professional hover patient info todo list
export const onHoverPatientInfoTodoList = payload => ({
  type: PROFESSIONAL_HOVERS_PATIENTINFO_TODOLIST,
  payload
});

// Track professional hover patient comms history todo list
export const onHoverCommsHistoryTodoList = payload => ({
  type: PROFESSIONAL_HOVERS_COMMSHISTORY_TODOLIST,
  payload
});

// Track professional update filters todo list
export const onUpdateFilterTodoList = payload => ({
  type: PROFESSIONAL_UPDATE_FILTER_TODOLIST,
  payload
});

// Track professional update sorting todo list
export const onUpdateSortTodoList = payload => ({
  type: PROFESSIONAL_UPDATE_SORT_TODOLIST,
  payload
});

// Track professional click kebab on todo list
export const onClickKebabTableLineTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_KEBAB_TABLELINE_TODOLIST,
  payload
});

// Track professional enter todo list
export const onOpenTodoList = payload => ({
  type: PROFESSIONAL_OPENS_TODOLIST,
  payload
});

// Track professional transcribe prom todo list
export const onClickTranscribePromTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_TRANSCRIBEPROM_TODOLIST,
  payload
});

// Track professional click manage columns menu todo list
export const onClickManageColumnsTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_MANAGE_COLUMNS_TODOLIST,
  payload
});

// Track professional click close manage columns drawer todo list
export const onClickCloseManageColumnsTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_CLOSE_MANAGE_COLUMNS_TODOLIST,
  payload
});

// Track professional save columns change on todo list
export const onClickSaveManageColumnsTodoList = payload => ({
  type: PROFESSIONAL_CLICKS_SAVE_MANAGE_COLUMNS_TODOLIST,
  payload
});

// -------- END TodoList V2 ----------
